<template>
  <div class="sign-up">
    <form>
      <h2>SIGN UP FOR ISHINER</h2>
      <div class="inputs">
        <div class="input">
          <input
            type="text"
            id="nickname"
            required
            v-model="nickname"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="nickname">
            <span style="transition-delay: 0ms">N</span>
            <span style="transition-delay: 50ms">i</span>
            <span style="transition-delay: 100ms">c</span>
            <span style="transition-delay: 150ms">k</span>
            <span style="transition-delay: 200ms">n</span>
            <span style="transition-delay: 250ms">a</span>
            <span style="transition-delay: 300ms">m</span>
            <span style="transition-delay: 350ms">e</span>
            <span style="transition-delay: 400ms">&nbsp;*</span>
          </label>
        </div>
        <div class="input email">
          <input
            type="text"
            id="email"
            required
            v-model="email"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="email">
            <span style="transition-delay: 0ms">E</span>
            <span style="transition-delay: 50ms">-</span>
            <span style="transition-delay: 100ms">m</span>
            <span style="transition-delay: 150ms">a</span>
            <span style="transition-delay: 200ms">i</span>
            <span style="transition-delay: 250ms">l</span>
            <span style="transition-delay: 300ms">&nbsp;*</span>
          </label>
        </div>
        <div class="input pwd1">
          <input
            :type="pwd1Type"
            id="pwd1"
            required
            v-model="password1"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="pwd1">
            <span style="transition-delay: 0ms">P</span>
            <span style="transition-delay: 50ms">a</span>
            <span style="transition-delay: 100ms">s</span>
            <span style="transition-delay: 150ms">s</span>
            <span style="transition-delay: 200ms">w</span>
            <span style="transition-delay: 250ms">o</span>
            <span style="transition-delay: 300ms">r</span>
            <span style="transition-delay: 350ms">d</span>
            <span style="transition-delay: 400ms">&nbsp;</span>
            <span style="transition-delay: 450ms">1</span>
            <span style="transition-delay: 500ms">&nbsp;*</span>
          </label>
          <div class="view-icon">
            <el-icon v-if="showPwd1" @click="hidePassword(1)"
              ><sunny
            /></el-icon>
            <el-icon v-else @click="showPassword(1)"><sunrise /></el-icon>
          </div>
        </div>
        <div class="input pwd2">
          <input
            :type="pwd2Type"
            id="pwd2"
            required
            v-model="password2"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="pwd2">
            <span style="transition-delay: 0ms">P</span>
            <span style="transition-delay: 50ms">a</span>
            <span style="transition-delay: 100ms">s</span>
            <span style="transition-delay: 150ms">s</span>
            <span style="transition-delay: 200ms">w</span>
            <span style="transition-delay: 250ms">o</span>
            <span style="transition-delay: 300ms">r</span>
            <span style="transition-delay: 350ms">d</span>
            <span style="transition-delay: 400ms">&nbsp;</span>
            <span style="transition-delay: 450ms">2</span>
            <span style="transition-delay: 500ms">&nbsp;*</span>
          </label>
          <div class="view-icon">
            <el-icon v-if="showPwd2" @click="hidePassword(2)"
              ><sunny
            /></el-icon>
            <el-icon v-else @click="showPassword(2)"><sunrise /></el-icon>
          </div>
        </div>
        <div class="input institution">
          <input
            type="text"
            id="institution"
            optional
            v-model="institution"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="institution">
            <span style="transition-delay: 0ms">I</span>
            <span style="transition-delay: 50ms">n</span>
            <span style="transition-delay: 100ms">s</span>
            <span style="transition-delay: 150ms">t</span>
            <span style="transition-delay: 200ms">i</span>
            <span style="transition-delay: 250ms">t</span>
            <span style="transition-delay: 300ms">u</span>
            <span style="transition-delay: 350ms">t</span>
            <span style="transition-delay: 400ms">i</span>
            <span style="transition-delay: 450ms">o</span>
            <span style="transition-delay: 500ms">n</span>
          </label>
        </div>
        <div class="input phone">
          <input
            type="text"
            id="phone"
            required
            v-model="phone"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="phone">
            <span style="transition-delay: 0ms">P</span>
            <span style="transition-delay: 50ms">h</span>
            <span style="transition-delay: 100ms">o</span>
            <span style="transition-delay: 150ms">n</span>
            <span style="transition-delay: 200ms">e</span>
          </label>
        </div>
        <div class="input country">
          <input
            type="text"
            id="country"
            required
            autocomplete="off"
            v-model="country"
            @focus="onFocus"
            @blur="onBlur"
            @input="filterData"
          />
          <label class="label" for="country">
            <span style="transition-delay: 0ms">C</span>
            <span style="transition-delay: 50ms">o</span>
            <span style="transition-delay: 100ms">u</span>
            <span style="transition-delay: 150ms">n</span>
            <span style="transition-delay: 200ms">t</span>
            <span style="transition-delay: 250ms">r</span>
            <span style="transition-delay: 300ms">y</span>
          </label>
          <el-icon class="select-icon"><caret-bottom /></el-icon>
          <div class="popper" ref="countryPopper">
            <ul>
              <li
                class="option a1"
                @click="selectCountry"
                v-for="item in allCountry"
                :key="item.english_name"
              >
                {{ item.english_name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="input position">
          <input
            type="text"
            id="position"
            required
            autocomplete="off"
            v-model="position"
            @focus="onFocus"
            @blur="onBlur"
            @input="filterData"
          />
          <label class="label" for="position">
            <span style="transition-delay: 0ms">P</span>
            <span style="transition-delay: 50ms">o</span>
            <span style="transition-delay: 100ms">s</span>
            <span style="transition-delay: 150ms">i</span>
            <span style="transition-delay: 200ms">t</span>
            <span style="transition-delay: 250ms">i</span>
            <span style="transition-delay: 300ms">o</span>
            <span style="transition-delay: 350ms">n</span>
          </label>
          <el-icon class="select-icon"><caret-bottom /></el-icon>
          <div class="popper" ref="positionPopper">
            <ul>
              <li
                class="option"
                @click="selectPosition"
                v-for="item in allPosition"
                :key="item.name"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="input code">
          <input
            type="text"
            id="code"
            autocomplete="off"
            v-model="code"
            @focus="onFocus"
            @blur="onBlur"
          />
          <label class="label" for="code">
            <span style="transition-delay: 0ms">V</span>
            <span style="transition-delay: 50ms">e</span>
            <span style="transition-delay: 100ms">r</span>
            <span style="transition-delay: 150ms">i</span>
            <span style="transition-delay: 200ms">f</span>
            <span style="transition-delay: 250ms">y</span>
          </label>
          <button
            type="button"
            id="send-code"
            @click="sendCode"
            :disabled="!isTimeOut"
          >
            {{ isTimeOut ? "Send Code" : "Resend in " + timer + " s" }}
          </button>
        </div>
      </div>
      <button type="button" id="btn" @click="register">REGISTER</button>
      <p>
        Already hava an account?
        <router-link to="/account/sign-in">To sign in</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import { userApi } from "../../api/index";
import { countryCode, positions } from "../../api/data/country_position_data";
export default {
  name: "sign-up",
  components: {},
  data() {
    return {
      nickname: "",
      email: "",
      password1: "",
      password2: "",
      country: "",
      position: "",
      phone: "",
      institution: "",
      showPwd1: false,
      showPwd2: false,
      pwd1Type: "password",
      pwd2Type: "password",
      filterCountryName: "",
      filterPositionName: "",
      code: "",
      isTimeOut: true,
      timer: 90,
    };
  },
  methods: {
    // 表单检查
    checkForm() {
      let emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
      if (this.nickname == "") {
        // 用户名不为空
        this.$store.commit("alertMsg", {
          msg: "Please enter your nickname!",
          type: "error",
        });
        return false;
      }
      if (!emailReg.test(this.email)) {
        // 邮箱格式
        this.$store.commit("alertMsg", {
          msg: "Check Your E-mail Format!",
          type: "error",
        });
        return false;
      }
      if (this.password1 == "" || this.password2 == "") {
        // 填写两个密码
        this.$store.commit("alertMsg", {
          msg: "Please enter your password!",
          type: "error",
        });
        return false;
      }
      if (this.password1 != this.password2) {
        // 两个密码必须相同
        this.$store.commit("alertMsg", {
          msg: "Different Passwords!",
          type: "error",
        });
        return false;
      }
      return true;
    },
    // 验证code
    sendCode() {
      if (this.checkForm()) {
        // 检验用户名、邮箱是否可用
        userApi
          .register_check(this.email, this.nickname)
          .then((res1) => {
            // 可用
            if (res1.code == 200) {
              // 发送code
              userApi
                .sendCode(this.email)
                .then((res) => {
                  //verify code发送成功
                  if (res.code == 200) {
                    this.isTimeOut = false;
                    this.timer = 90;
                    this.$store.commit("alertMsg", {
                      msg: `Sucessfully send verify code to ${this.email}`,
                      type: "ok",
                    });
                    // 启动倒计时
                    const time = setInterval(() => {
                      this.timer--;
                      if (this.timer == 0) {
                        this.isTimeOut = true;
                        clearInterval(time);
                      }
                    }, 999);
                  } else {
                    this.$store.commit("alertMsg", {
                      msg: res.msg,
                      type: "error",
                    });
                  }
                })
                .catch((err) => {
                  this.$store.commit("alertMsg", {
                    msg: err,
                    type: "error",
                  });
                });
            }
            // 检验不通过
            else if (res1.code == 1001) {
              this.$store.commit("alertMsg", {
                msg: "The nickname has been registered already!",
                type: "error",
              });
            } else if (res1.code == 1002) {
              this.$store.commit("alertMsg", {
                msg: "This email has been registered already!",
                type: "error",
              });
            } else {
              this.$store.commit("alertMsg", {
                msg: "Unkown error!",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
          });
      }
    },
    // 注册
    register() {
      if (!this.checkForm()) return;
      // 验证码为空
      if (this.code == "" || !this.code) {
        this.$store.commit("alertMsg", {
          msg: "Please enter the verify code from your email!",
          type: "error",
        });
        return;
      }
      // 整理所有信息
      const data = {
        nickname: this.nickname,
        password1: this.password1,
        password2: this.password2,
        institution: this.institution,
        phone: this.phone,
        country: this.country,
        email: this.email,
        position: this.position,
        code: this.code,
      };

      // 发送请求
      userApi
        .register(data)
        .then((res) => {
          //发送成功
          if (res.code == 200) {
            this.$store.commit("alertMsg", {
              msg: "Sucessfully registered! The page will jump to login soon...",
              type: "ok",
            });
            setTimeout(() => {
              // 注册成功3s后跳转到登陆页面
              this.$router.replace("/account/sign-in");
            }, 3000);
          }
          // 错误处理
          else if (res.code == 1001) {
            this.$store.commit("alertMsg", {
              msg: "The nickname has been registered already!",
              type: "error",
            });
          } else if (res.code == 1002) {
            this.$store.commit("alertMsg", {
              msg: "This E-mail has been registered already!",
              type: "error",
            });
          } else if (res.code == 1003) {
            this.$store.commit("alertMsg", {
              msg: "Wrong verify code or the code is outdated!",
              type: "error",
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    // 聚焦到输入框
    onFocus(e) {
      e.target.classList.add("active");
      if (e.target.id == "country") {
        this.$refs.countryPopper.classList.add("active");
      }
      if (e.target.id == "position") {
        this.$refs.positionPopper.classList.add("active");
      }
    },
    // 输入框失焦
    onBlur(e) {
      if (e.target.id == "country" || e.target.id == "position") {
        setTimeout(() => {
          if (!e.target.value.length) e.target.classList.remove("active");
          if (e.target.id == "country") {
            this.$refs.countryPopper.classList.remove("active");
          }
          if (e.target.id == "position") {
            this.$refs.positionPopper.classList.remove("active");
          }
        }, 300);
      } else {
        if (!e.target.value.length) e.target.classList.remove("active");
      }
    },
    selectCountry(e) {
      this.country = e.target.innerText;
    },
    selectPosition(e) {
      this.position = e.target.innerText;
    },
    filterData(e) {
      if (e.target.id == "country") this.filterCountryName = e.target.value;
      if (e.target.id == "position") this.filterPositionName = e.target.value;
    },
    showPassword(id) {
      if (id == 1) {
        this.pwd1Type = "text";
        this.showPwd1 = true;
      } else {
        this.pwd2Type = "text";
        this.showPwd2 = true;
      }
    },
    hidePassword(id) {
      if (id == 1) {
        this.pwd1Type = "password";
        this.showPwd1 = false;
      } else {
        this.pwd2Type = "password";
        this.showPwd2 = false;
      }
    },
  },
  computed: {
    // 筛选城市
    allCountry() {
      let pattern =
        this.filterCountryName && this.filterCountryName.toLowerCase();
      if (pattern == "") return countryCode;
      else {
        return countryCode.filter((item) => {
          return item.english_name.toLowerCase().match(pattern);
        });
      }
    },
    // 筛选职业
    allPosition() {
      let pattern =
        this.filterPositionName && this.filterPositionName.toLowerCase();
      if (pattern == "") return positions;
      else {
        return positions.filter((item) => {
          return item.name.toLowerCase().match(pattern);
        });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.sign-up {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

form {
  margin: 100px 0;
  padding: 30px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(18, 27, 89, 0.5);
  background: var(--color2-light);
  display: flex;
  flex-direction: column;
}

form h2 {
  margin: 20px 0;
  text-align: center;
  letter-spacing: 3px;
  color: var(--color1-dark);
}

form .input {
  width: 100%;
  margin: 25px 0;
  position: relative;
  font-weight: bold;
}
.inputs {
  display: flex;
  flex-flow: row wrap;
}

.input label {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}

.input label span {
  color: var(--color2-dark);
  font-size: 20px;
  display: inline-block;
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input input {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 0px;
  border: none;
  border-bottom: 2px solid var(--color2-dark);
  color: var(--color1-dark);
  background-color: var(--color2-light);
  box-shadow: inside 0 0 0 1000px var(--color2-light);
}

input.active + label span {
  transform: translateY(-150%);
  color: var(--color1-light);
}

input.active {
  outline: none;
  border-bottom: 3px solid var(--color1-light);
  color: var(--color1-dark);
}

#btn {
  text-align: center;
  height: 45px;
  margin: 30px 0;
  width: 300px;
  align-self: center;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);

  border-radius: 10px;
  box-shadow: 0 0 5px rgba(18, 27, 89, 0.5);
  border: none;
  cursor: pointer;
  font-size: 20px;
  /* color: white; */
  font-weight: bold;
}

#btn:hover,
#send-code:hover {
  background: linear-gradient(to left, #8bbbed, #e8f4ff);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--color2-light) inset;
}

form p {
  margin: 10px 0;
  text-align: center;
}
form p a {
  text-decoration: none;
  padding: 0 5px;
  font-weight: bold;
  color: var(--color1-light);
}

.popper {
  display: none;
  width: 100%;
  top: 105%;
  left: 0;
  position: absolute;
  z-index: 99;
  background-color: var(--color2-dark);
  border-radius: 5px;
}

.popper ul {
  overflow-y: scroll;
  max-height: 200px;
  list-style-type: none;
}

.option {
  height: 25px;
  text-align: center;
  font-size: 15px;
  padding: 5px 0;
  color: white;
  cursor: pointer;
}
.popper.active {
  display: block;
}

.option:hover {
  background-color: var(--color1-light);
}

.view-icon {
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
  color: var(--color1-dark);
  cursor: pointer;
}

.select-icon {
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
  color: var(--color1-dark);
  cursor: pointer;
}

form .input.code input {
  width: 60%;
  /* background: red; */
}

#send-code {
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  height: 45px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);
  cursor: pointer;
  box-shadow: 0 0 5px rgba(18, 27, 89, 0.5);
}
</style>
